/* -------------------- Rebrand Fonts -------------------- */

/* woff */
@font-face {
    font-family: 'Epidemic';
    font-weight: 500;
    src: url('../assets/fonts/epidemic/Epidemic-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Epidemic';
    font-weight: 600;
    src: url('../assets/fonts/epidemic/Epidemic-DemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Epidemic';
    font-weight: 700;
    src: url('../assets/fonts/epidemic/Epidemic-Bold.woff') format('woff');
}

/* woff2 */
@font-face {
    font-family: 'Epidemic';
    font-weight: 500;
    src: url('../assets/fonts/epidemic/Epidemic-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Epidemic';
    font-weight: 600;
    src: url('../assets/fonts/epidemic/Epidemic-DemiBold.woff2') format('woff2');
}

@font-face {
    font-family: 'Epidemic';
    font-weight: 700;
    src: url('../assets/fonts/epidemic/Epidemic-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Sebenta';
    src: url('../assets/fonts/epidemic/Sebenta-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Inter';
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hiJ-Ek-_EeA.woff2)
        format('woff2');
}

:root {
    --es-header-font-family: 'Sebenta', serif;
    --es-text-font-family: 'Inter', sans-serif;

    /* colours */
    --es-background-brand-fog: #cfd6e5;
    --es-background-brand-off-white: #f1f0eb;
    --es-background-brand-pink: #ff82c2;
}

html {
    background-color: #0f0f0f;
    font-family: var(--es-header-font-family);
    font-size: 1rem;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
}

body {
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 2rem;
}

#onetrust-consent-sdk * {
    font-family: var(--epidemic-font) !important;
}

.onetrust-pc-dark-filter,
#onetrust-banner-sdk {
    display: none !important;
}
